<template>
    <form class="form" novalidate="novalidate" id="st_legal_form">
        <div class="row mb-6">
            <div class="col-12 col-md-4">
                <div class="form-group">
                    <label>
                        {{ fields.company_name.label }} *
                    </label>
                    <div>
                        <st-input-text
                            v-model="model[fields.company_name.name]"
                            :ref="fields.company_name.name"
                            :name="fields.company_name.name"
                            :disabled="viewMode"
                        />
                    </div>
                </div>
                <div class="form-group">
                    <label>
                        {{ fields.iban.label }} *
                    </label>
                    <div>
                        <st-input-text
                            :ref="fields.iban.name"
                            :name="fields.iban.name"
                            v-model="model[fields.iban.name]"
                            :disabled="viewMode"
                        />
                    </div>
                </div>
                <div class="form-group">
                    <label>
                        {{ fields.email.label }} *
                    </label>
                    <div>
                        <st-input-text
                            v-model="model[fields.email.name]"
                            :ref="fields.email.name"
                            :name="fields.email.name"
                            :disabled="viewMode"
                        />
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-4">
                <div class="form-group">
                    <label>
                        {{ fields.unique_registration_code.label }} *
                    </label>
                    <div>
                        <st-input-text
                            v-model="
                                model[fields.unique_registration_code.name]
                            "
                            :ref="fields.unique_registration_code.name"
                            :name="fields.unique_registration_code.name"
                            :disabled="viewMode"
                        />
                    </div>
                </div>
                <div class="form-group">
                    <label>
                        {{ fields.bank.label }} *
                    </label>
                    <div>
                        <st-input-text
                            :ref="fields.bank.name"
                            :name="fields.bank.name"
                            v-model="model[fields.bank.name]"
                            :disabled="viewMode"
                        />
                    </div>
                </div>
                <div class="form-group">
                    <label>
                        {{ fields.phone_number.label }} *
                    </label>
                    <div>
                        <st-input-text
                            v-model="model[fields.phone_number.name]"
                            :ref="fields.phone_number.name"
                            :name="fields.phone_number.name"
                            :disabled="viewMode"
                            phone-validation
                        />
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-4">
                <div class="form-group">
                    <label>
                        {{ fields.registration_number.label }}
                    </label>
                    <div>
                        <st-input-text
                            v-model="
                                model[fields.registration_number.name]
                            "
                            :ref="fields.registration_number.name"
                            :name="fields.registration_number.name"
                            :disabled="viewMode"
                        />
                    </div>
                </div>
            </div>
        </div>
        {{ $t('BENEFICIARY.BENEFICIARY_DETAILS.OFFICE_DETAILS_HEADER') }}:
        <div class="row mb-6">
            <div class="col-12 col-md-4">
                <div class="form-group">
                    <label>
                        {{ fields.county_id.label }} *
                    </label>
                    <div>
                        <st-autocomplete
                            v-model="model[fields.county_id.name]"
                            :options="fields.county_id.options"
                            :ref="fields.county_id.name"
                            :name="fields.county_id.name"
                            :disabled="viewMode"
                            :fields="fields"
                            :formValidation="fv"
                            :model="model"
                        >
                        </st-autocomplete>
                    </div>
                </div>
                <div class="form-group">
                    <label>
                        {{ fields.street.label }} *
                    </label>
                    <div>
                        <st-input-text
                            :ref="fields.street.name"
                            :name="fields.street.name"
                            v-model="model[fields.street.name]"
                            allow-only-alphanumeric
                            :disabled="viewMode"
                        />
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-4">
                <div class="form-group">
                    <label>
                        {{ fields.locality_id.label }} *
                    </label>
                    <div>
                        <st-autocomplete
                            v-model="model[fields.locality_id.name]"
                            :options="fields.locality_id.options"
                            :ref="fields.locality_id.name"
                            :name="fields.locality_id.name"
                            :disabled="!fields.locality_id.options.length || viewMode"
                            :model="model"
                            :formValidation="fv"
                            :fields="fields"
                        ></st-autocomplete>
                    </div>
                </div>
                <div class="form-group">
                    <div class="row">
                        <div class="col-sm">
                            <label>
                                {{ fields.street_number.label }} *
                            </label>
                            <div>
                                <st-input-text
                                    :ref="fields.street_number.name"
                                    :name="fields.street_number.name"
                                    v-model="model[fields.street_number.name]"
                                    allow-only-alphanumeric
                                    :disabled="viewMode"
                                />
                            </div>
                        </div>
                        <div class="col-sm">
                            <label>
                                {{ fields.block.label }}
                            </label>
                            <div>
                                <st-input-text
                                    :ref="fields.block.name"
                                    :name="fields.block.name"
                                    v-model="model[fields.block.name]"
                                    :disabled="viewMode"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-4">
                <div class="form-group">
                    <label>
                        {{ fields.village_id.label }}
                    </label>
                    <div>
                        <st-autocomplete
                            v-model="model[fields.village_id.name]"
                            :options="fields.village_id.options"
                            :ref="fields.village_id.name"
                            :name="fields.village_id.name"
                            :disabled="!fields.village_id.options.length || viewMode"
                            :model="model"
                            :fields="fields"
                            :formValidation="fv"
                        >
                        </st-autocomplete>
                    </div>
                </div>
                <div class="form-group">
                    <div class="row">
                        <div class="col-sm">
                            <div class="form-group">
                            <label>
                                {{ fields.apartment.label }}
                            </label>
                            <div>
                                <st-input-text
                                    :ref="fields.apartment.name"
                                    :name="fields.apartment.name"
                                    v-model="model[fields.apartment.name]"
                                    :disabled="viewMode"
                                />
                            </div>
                        </div>
                        </div>
                        <div class="col-sm">
                            <label>
                                {{ fields.postal_code.label }}
                            </label>
                            <div>
                                <st-input-text
                                    :ref="fields.postal_code.name"
                                    :name="fields.postal_code.name"
                                    v-model="model[fields.postal_code.name]"
                                    set-validation
                                    :max-length="6"
                                    :disabled="viewMode"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {{ $t('BENEFICIARY.BENEFICIARY_DETAILS.PERSON_DETAILS_HEADER') }}:
        <div class="row mb-6">
            <div class="col-12 col-md-4">
                <div class="form-group">
                    <label>
                        {{ fields.last_name.label }} *
                    </label>
                    <div>
                        <st-input-text
                            :ref="fields.last_name.name"
                            :name="fields.last_name.name"
                            v-model="model[fields.last_name.name]"
                            allow-only-letters
                            :disabled="viewMode"
                        />
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-4">
                <div class="form-group">
                    <label>
                        {{ fields.first_name.label }} *
                    </label>
                    <div>
                        <st-input-text
                            :ref="fields.first_name.name"
                            :name="fields.first_name.name"
                            v-model="model[fields.first_name.name]"
                            allow-only-letters
                            :disabled="viewMode"
                        />
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-4">
                <div class="form-group">
                    <label>
                        {{ fields.personal_numeric_code.label }} *
                    </label>
                    <div>
                        <st-input-text
                            :ref="fields.personal_numeric_code.name"
                            :name="fields.personal_numeric_code.name"
                            v-model="model[fields.personal_numeric_code.name]"
                            set-validation
                            :max-length="13"
                            :disabled="viewMode"
                        />
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-2">
                <div class="form-group">
                    <label>
                        {{ fields.id_card_serial.label }} *
                    </label>
                    <div label="">
                        <st-input-text
                            v-model="model[fields.id_card_serial.name]"
                            :ref="fields.id_card_serial.name"
                            :name="fields.id_card_serial.name"
                        />
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-2">
                <div class="form-group">
                    <label>
                        {{ fields.id_card_number.label }} *
                    </label>
                    <div label="">
                        <st-input-text
                            v-model="model[fields.id_card_number.name]"
                            :ref="fields.id_card_number.name"
                            :name="fields.id_card_number.name"
                        />
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-4">
                <div class="form-group">
                    <label>
                        {{ fields.id_card_expiration_date.label }} *
                    </label>
                    <div label="">
                        <st-datepicker
                            v-model="model[fields.id_card_expiration_date.name]"
                            :ref="fields.id_card_expiration_date.name"
                            :name="fields.id_card_expiration_date.name"
                            :min="min"
                        />
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-4">
                <div class="form-group">
                    <label>
                        {{ fields.id_card_issuer.label }} *
                    </label>
                    <div label="">
                        <st-input-text
                            v-model="model[fields.id_card_issuer.name]"
                            :ref="fields.id_card_issuer.name"
                            :name="fields.id_card_issuer.name"
                        />
                    </div>
                </div>
            </div>             
            <div class="col-12" v-if="showUseProfileData">
                <b-form-checkbox
                    v-model="model[fields.use_profile_data.name]"
                    size="lg"
                    :disabled="viewMode"
                    @change="onChangeUseMyProfileData"
                >
                    {{ fields.use_profile_data.label }}
                </b-form-checkbox>
            </div>
        </div>
    </form>
</template>

<script>
import { mapGetters } from "vuex";
import { BeneficiaryModel } from "@/modules/applications/models/beneficiary-model";
import { FormSchema } from "@/shared/form/form-schema";
import { createFormValidation } from "@/shared/utils/create-form-validation";
import { idCard } from '@/shared/utils/id-card';

const { fields } = BeneficiaryModel;
const formSchema = new FormSchema([
    fields.id,
    fields.user_type_name,
    fields.personal_numeric_code,
    fields.first_name,
    fields.last_name,
    fields.license,
    fields.unique_registration_code,
    fields.registration_number,
    fields.company_name,
    fields.iban,
    fields.bank,
    fields.county_id,
    fields.locality_id,
    fields.village_id,
    fields.sector,
    fields.street,
    fields.street_number,
    fields.postal_code,
    fields.staircase,
    fields.block,
    fields.floor,
    fields.apartment,
    fields.email,
    fields.phone_number,
    fields.use_profile_data,
    fields.id_card_serial,
    fields.id_card_number,
    fields.id_card_expiration_date,
    fields.id_card_issuer,    
]);

export default {
    name: "LegalBeneficiaryForm",
    props: {
        viewMode: {
            type: Boolean,
            default: false,
        },
        item: {
            type: Object,
        },
    },
    data() {
        return {
            model: {},
            fields,
            rules: formSchema.rules(),
            fv: null,
            min: idCard.expirationDate            
        };
    },
    computed: {
        ...mapGetters({
            moduleName: "shared/moduleName",
            currentUser: "auth/currentUser",
        }),
        record() {
            const currentState = this.moduleName ?? 'applications';
            return this.$store.state[currentState]?.form?.record;
        },
        showUseProfileData() {
            return this.currentUser.user_type_name === "legal_entity";
        },
        useMyProfileData() {
            return this.model.use_profile_data;
        },
    },
    created() {
        const record = this.getInitialModelValues(!!this.item);
        this.model = formSchema.initialValues(record);
    },
    mounted() {
        this.fv = createFormValidation("st_legal_form", this.rules);
    },
    methods: {
        getInitialModelValues(selected = false) {
            const userType = {
                user_type_name: "legal_entity",
            };

            if ((this.viewMode || this.record?.status === "draft") && this.item) {
                return {
                    ...this.item
                };
            } else {
                if (this.item) {
                    return { ...this.item, ...userType };
                }

                if (!selected && this.showUseProfileData) {
                    const user = Object.assign({}, this.currentUser);
                    delete user.id;

                    return {
                        ...user,
                        ...userType,
                        ...{ use_profile_data: true },
                    };
                }

                return userType;
            }
        },
        onChangeUseMyProfileData() {
            if (this.useMyProfileData) {
                this.populateUserDetailsWithCurrentUser();
                return;
            }
            this.clearUserDetails();
        },
        populateUserDetailsWithCurrentUser(selected = false) {
            const userDetails = this.getInitialModelValues(selected);
            const localityId = userDetails.locality_id;
            const villageId = userDetails.village_id;
            const values = { ...this.model, ...userDetails };
            this.model = values;
            this.$nextTick(()=>{
                this.model.locality_id = localityId;
                this.$nextTick(() => {
                    this.model.village_id = villageId;
                })
            })
        },
        clearUserDetails() {
            this.model = {
                use_profile_data: false,
                county_id: null,
                locality_id: null,
                village_id: null,
                user_type_name: this.model.user_type_name
            };

            if (this.fv.fields.user_type_name)  this.fv.removeField('user_type_name');
            this.fv.resetForm(true);
        },
    },
    watch: {
        item: {
            deep: true,
            handler(item) {
                this.model[fields.use_profile_data.name] = false;
                this.populateUserDetailsWithCurrentUser(true);
            }
        }
    }
};
</script>
